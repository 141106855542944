/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '@ionic/angular/css/ionic-swiper';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '@angular/material/theming';
@include mat-core();


/*
Fonts
 */
 @font-face {
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PoppinsB';
  src: url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PoppinsBolder';
  src: url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

.poppinsBolder {
  font-family: 'PoppinsBolder';
}

.poppinsB {
  font-family: 'PoppinsB';
}

.poppins {
  font-family: 'Poppins';
}


* {
  outline: none !important;
  font-family: 'Poppins';
}

$matgold: (
  50 : #4C5759,
  100 : #4C5759,
  200 : #4C5759,
  300 : #4C5759,
  400 : #4C5759,
  500 : #4C5759,
  600 : #4C5759,
  700 : #4C5759,
  800 : #4C5759,
  900 : #4C5759,
  A100 : #ffffff,
  A200 : #fffefc,
  A400 : #ffeec9,
  A700 : #ffe7af,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$my-app-primary: mat-palette($matgold, 500);
$my-app-accent: mat-palette($mat-pink, 500, 900, A100);
$my-app-warn: mat-palette($mat-deep-orange);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include angular-material-theme($my-app-theme);

.alternate-theme {
  $alternate-primary: mat-palette($mat-light-blue);
  $alternate-accent: mat-palette($mat-yellow, 400);
  $alternate-theme: mat-light-theme($alternate-primary, $alternate-accent);
  @include angular-material-theme($alternate-theme);
}


.newsletter {
  --height: 450px;
}


// ALERT BASIC
.alert-basic {
  .alert-wrapper {
    .alert-head {
      .alert-title {
        color: var(--ion-color-tn-black) !important;
      }
    }

    .alert-button-group {
      .alert-button {
        font-weight: bold;
        color: var(--ion-color-tn-black) !important;
      }

      .alert-button-role-cancel {
        font-weight: bold;
        color: var(--ion-color-danger) !important;
      }
    }
  }
}


// ALERT SUCCESS
.alert-success {
  .alert-wrapper {
    background: var(--ion-color-tn-beige);
    .alert-head {
      .alert-title {
        color: var(--ion-color-tn-black) !important;
      }
    }
    .alert-message {
      color: var(--ion-color-tn-black) !important;
      font-weight: bold !important;
      background: var(--ion-color-tn-beige) !important;
      padding: 15px !important;
      border: none !important;
    }
    .alert-button-group {
      background: var(--ion-color-tn-beige) !important;
      border-radius: 0px !important;
      border: none !important;

      .alert-button {
        color: var(--ion-color-tn-black) !important;
        font-weight: bold;
      }

      .alert-button-role-cancel {
        color: var(--ion-color-danger) !important;
        font-weight: bold;
      }
    }
  }
}

// ALERT DANGER 
.alert-danger {
  .alert-wrapper {
    background: var(--ion-color-danger);
    .alert-head {
      .alert-title {
        color: white;
      }
    }
    .alert-message {
      color: white !important;
      font-weight: bold !important;
      background: var(--ion-color-danger) !important;
      padding: 15px !important;
      border: none !important;
    }
    .alert-button-group {
      background: var(--ion-color-danger) !important;
      border-radius: 0px !important;
      border: none !important;
      .alert-button {
        color: white !important;
      }
    }
  }
}





//Calendar

.table-bordered {
  thead {
    background: var(--ion-color-tn-black) !important;
    color: white !important;
    font-size: 13px !important;
  }

  tbody {
    font-size: 13px !important;
    font-weight: bold !important;
  }
}

.event-detail-container {
  border-top: 10px var(--ion-color-tn-black) solid !important;
  background: transparent !important;

  ion-item {
    border: 1px solid var(--ion-color-tn-black);
    border-radius: 10px;
    margin: 5px;
  }
}

.calendar-event-inner {
  background-color: #3aa5da !important;
}

.weekview-allday-table {
  display: none !important;
}

.dayview-allday-table {
  display: none !important;
}

weekview-normal-event-container {
  margin-top: -10px !important;
}

// Title
ion-title {
  font-weight: bold;
}


.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
}


.scrollHidden {
  --overflow: hidden;
}

.titleHeader {
  display: flex;
  justify-content: center;
  width: 100%;
}

.subContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

// .underline {
//   font-family: "GT Font Bold";
//   font-size: 40px;
//   text-decoration: underline;
//   text-decoration-color: rgb(227, 203, 128);
// }

.text {
  padding: 20px;
  font-family: "Text Font";
  text-align: justify;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px;
}


// Position
.center {
  display: flex;
  justify-content: center;
}

.time-modal {
  --height: 300px !important;
  --width: 90%;
  --backdrop-opacity: 0.5;
  --border-radius: 15px;

  ion-datetime {
    height: 100%;
    width: 100%;
    background-color: white !important;
    --background: white;

    div {
      color: var(--ion-color-primary);
    }
  }
}

// Divider
ion-item-divider {
  --background: var(--ion-color-primary);
  min-height: 5px !important;
  margin-top: 10px;
}



// :host ::ng-deep .picker-column-numeric-input .picker-item-active {
//   border-top: 1px solid var(--ion-color-primary) !important;
//   border-bottom: 1px solid var(--ion-color-primary) !important;
// }

// ANIMATION
.slide-in-top {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}


.modalTime {
  --width: 400px;
  --height: 255px;
}

//LOADER

.loader-css-class {
  --background: white;
  --spinner-color: var(--ion-color-primary);
}

.object-fit-contain {
  object-fit: contain;
}

// ACTIVE MENU NAV

.active-link {
  --ion-text-color: var(--ion-color-primary);
  font-weight: bold;
}

.black {
  color: black;
  --color: black;
}

.tn-black {
  color: var(--ion-color-tn-black);
  --color: var(--ion-color-tn-black);
}

.white {
  color: white;
  --color: white;
}

.font-bold {
  font-weight: bold;
}

// BACKGROUND COLOR

.bg-gold {
  background: #e3cb80;
  background-color: #e3cb80;
  --background: #e3cb80;
}

.bg-darkbeige {
  background: #F3ECE3;
  --background: #F3ECE3;
  background-color: #F3ECE3;
}

.bg-tnblack {
  background: var(--ion-color-tn-black);
  --background: var(--ion-color-tn-black);
}

.bg-rose {
  background: var(--ion-color-tn-rose);
  --background: var(--ion-color-tn-rose);
}

.bg-orange {
  background: var(--ion-color-tn-orange);
  --background: var(--ion-color-tn-orange);
}

.bg-blue {
  background: var(--ion-color-tn-blue);
  --background: var(--ion-color-tn-blue);
}





.bg-trans {
  background: transparent;
  --background: transparent;
}

.bg-beige {
  --background: #f9f5f0;
  background: #f9f5f0;
}

.bg-light {
  --background: #F9F5F0;
  background: #F9F5F0;
}

.bg-white {
  background: white;
}


// ion-content {
//   --background: #F9F5F0;
// }
// ion-footer {
//   background: #F9F5F0;
// }



// FLEX
.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

// PADDING

.p-0 {
  padding: 0px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-50 {
  padding: 50px;
}

.pad-lr-30 {
  padding-left: 30px;
  padding-right: 30px;
}

// Time modal
.time-modal {
  --height: 250px !important;
  --width: 400px;
  --backdrop-opacity: 0.5;
  --border-radius: 15px;

  ion-datetime {
    height: 100%;
    width: 100%;
    background-color: white;

    div {
      color: var(--ion-color-primary);
    }
  }
}

.pad-lr-59 {
  padding-left: 50px;
  padding-right: 50px;
}

.pad-t-40 {
  padding-top: 40px;
}

// MARGIN-TOP
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-100 {
  margin-top: 100px;
}

// MARGIN-BOTTOM
.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-100 {
  margin-bottom: 100px;
}

// FONT SIZE
.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-15 {
  font-size: 15px;
}

.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

// TEXT STYLES

.italic {
  font-style: italic;
}

.text-justify {
  text-align: justify;
}

.text-center {
  text-align: center;
}

// CURSOR
.cursor {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

// HOVER
.underline-hover:hover {
  text-decoration: underline;
}


// BORDER RADIUS

.rad5 {
  border-radius: 5px;
}

.rad10 {
  border-radius: 10px;
}

.rad15 {
  border-radius: 15px;
}

// BOX-SHADOW

.box-shadow {
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}


@media screen and (max-width: 1100px) {

  // Time modal
  .time-modal {
    --height: 300px !important;
    --width: 400px;
    --backdrop-opacity: 0.5;
    --border-radius: 15px;

    ion-datetime {
      height: 100%;
      width: 100%;
      background-color: white;

      div {
        color: var(--ion-color-primary);
      }
    }
  }

  .pad-lr-50 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .fs-35 {
    font-size: 25px !important;
  }

  .fs-30 {
    font-size: 20px !important;
  }

  .fs-25 {
    font-size: 15px;
  }

  .fs-20 {
    font-size: 13px;
  }
}

// ------------------------------
// BACKGROUND COLOR

.bg-success {
  background-color: var(--ion-color-success);
  --background: var(--ion-color-success);
}

.bg-danger {
  background-color: var(--ion-color-danger) !important;
  --background: var(--ion-color-danger);
}

.bg-trans {
  background: transparent;
  --background: transparent;
}

.bg-white {
  background: white;
  --background: white;
}

.bg-primary {
  background: var(--ion-color-primary);
  --background: var(--ion-color-primary);
}

.bg-secondary {
  background: var(--ion-color-secondary);
  --background: var(--ion-color-secondary);
}

.bg-black {
  background: black;
}

.bg-grey {
  background: rgba(161, 170, 174, 0.1);
  --background: rgba(161, 170, 174, 0.1);
}

.bg-tn-black {
  background: var(--ion-color-tn-black);
  --background: var(--ion-color-tn-black);
}

//   background-color: var(--ion-color-sucess);
.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inherit {
  position: inherit;
}

.bottom-0 {
  bottom: 0px;
}

.bottom-20 {
  bottom: 20px;
}

.bottom-50 {
  bottom: 50px;
}

.bottom-80 {
  bottom: 80px;
}

.bottom-100 {
  bottom: 100px;
}

.top-0 {
  top: 0px;
}

.top-100 {
  top: 100px;
}

.right-0 {
  right: 0px;
}

.left-0 {
  left: 0px;
}

.right-20 {
  right: 20px;
}

.left-20 {
  left: 20px;
}


// Z-INDEX
.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-100 {
  z-index: 100;
}

.z-1000 {
  z-index: 1000;
}

.z-10000 {
  z-index: 10000;
}

.z--1 {
  z-index: -1;
}

// FONT-SIZE
.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-27 {
  font-size: 27px;
}

.fs-29 {
  font-size: 29px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.fs-80 {
  font-size: 80px;
}

.fs-100 {
  font-size: 100px;
}

// GRID
.grid-1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

// PADDING
.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-100 {
  padding: 100px;
}

// PADDING-LEFT
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}
.pl-18 {
  padding-left: 18px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-23 {
  padding-left: 23px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-100 {
  padding-left: 100px;
}

// PADDING-RIGHT
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-100 {
  padding-right: 100px;
}

// PADDING-BOTTOM
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-100 {
  padding-bottom: 100px;
}

// PADDING-TOP 
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-100 {
  padding-top: 100px;
}

// WIDTH
.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-quarter {
  width: 25%;
}

.w-fit {
  width: fit-content;
}

.w-0 {
  width: 0px !important;
}

.w-10 {
  width: 10px;
}

.w-20 {
  width: 20px;
}

.w-25 {
  width: 25px;
}

.w-30 {
  width: 30px;
}

.w-40 {
  width: 40px;
}

.w-50 {
  width: 50px;
}

.w-60 {
  width: 60px;
}

.w-80 {
  width: 80px;
}

.w-100 {
  width: 100px
}

.w-150 {
  width: 150px;
}

.w-200 {
  width: 200px
}

.w-240 {
  width: 240px;
}

.w-300 {
  width: 300px;
}

.w-400 {
  width: 400px;
}

.w-450 {
  width: 450px;
}

// HEIGHT
.h-full {
  height: 100%;
}

.h-half {
  height: 50%;
}

.h-quarter {
  height: 25%;
}

.h-fit {
  height: fit-content;
}

.h-0 {
  height: 0px !important;
}

.h-10 {
  height: 10px;
}

.h-20 {
  height: 20px;
}

.h-25 {
  height: 25px;
}

.h-30 {
  height: 30px;
}

.h-40 {
  height: 40px;
}

.h-50 {
  height: 50px;
}

.h-60 {
  height: 60px;
}

.h-70 {
  height: 70px;
}

.h-80 {
  height: 80px;
}

.h-100 {
  height: 100px
}

.h-150 {
  height: 150px;
}

.h-200 {
  height: 200px
}

.h-250 {
  height: 250px;
}

.h-300 {
  height: 300px;
}

.h-350 {
  height: 350px;
}

.h-400 {
  height: 400px;
}

.h-450 {
  height: 450px;
}

.h-2rem {
  height: 2rem;
}

// FLEX
.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

// MARGIN-LEFT
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

// MARGIN-RIGHT
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

// MARGIN-TOP
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

// MARGIN
.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

// MARGIN-BOTTOM
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

// TEXT-STYLE
.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.text-justify {
  text-align: justify;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

// BORDER-RADIUS
.border-radius-5 {
  border-radius: 5px;
  --border-radius: 5px;
}

.border-radius-10 {
  border-radius: 10px;
  --border-radius: 10px;
}

.border-radius-20 {
  border-radius: 20px;
  --border-radius: 20px;
}

.border-radius-100 {
  border-radius: 100%;
  --border-radius: 100%;
}

.border-radius-half {
  border-radius: 50%;
  --border-radius: 50%;
}

// BORDER
.border-bottom {
  border-bottom: 1px;
}

.border-top {
  border-top: 1px;
}

.border-left {
  border-left: 1px;
}

.border-right {
  border-right: 1px;
}

.border-solid {
  border-style: solid;
}

.border-grey {
  border: 1px solid grey;
}

// COLOR
.grey {
  color: grey;
}

.white {
  color: white;
}

.tn-black {
  color: var(--ion-color-tn-black);
  --color: var(--ion-color-tn-black);
}

.black {
  color: black;
}

.primary {
  color: var(--ion-color-primary);
  --color: var(--ion-color-primary);
}

.red {
  color: red;
}

// CURSOR
.cursor-pointer {
  cursor: pointer;
}

// OVERFLOW
.overflow-auto {
  overflow: auto;
}

// OBJECT-FIT
.object-cover {
  object-fit: cover;
}

.object-contain {
  object-fit: contain;
}

.object-fill {
  object-fit: fill;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inherit {
  position: inherit;
}


.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-100 {
  z-index: 100;
}

.z-200 {
  z-index: 200;
}

.z-1000 {
  z-index: 1000;
}

.z-10000 {
  z-index: 10000;
}

.z--1 {
  z-index: -1;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-27 {
  font-size: 27px;
}

.fs-29 {
  font-size: 29px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.fs-80 {
  font-size: 80px;
}

.fs-100 {
  font-size: 100px;
}

.grid-1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.p-3 {
  padding: 3px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-100 {
  padding: 100px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-23 {
  padding-left: 23px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-100 {
  padding-left: 100px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-100 {
  padding-right: 100px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-100 {
  padding-top: 100px;
}

.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-quarter {
  width: 25%;
}

.w-fit {
  width: fit-content;
}

.w-0 {
  width: 0px !important;
}

.w-5 {
  width: 5px;
}

.w-10 {
  width: 10px;
}

.w-12 {
  width: 12px;
}

.w-20 {
  width: 20px;
}

.w-30 {
  width: 30px;
}

.w-40 {
  width: 40px;
}

.w-50 {
  width: 50px;
}

.w-75 {
  width: 75px;
}

.w-80 {
  width: 80px;
}

.w-100 {
  width: 100px
}

.w-150 {
  width: 150px;
}

.w-200 {
  width: 200px
}

.w-300 {
  width: 300px;
}

.w-400 {
  width: 400px;
}

.w-450 {
  width: 450px;
}

.w-500 {
  width: 500px;
}

.mw-500 {
  max-width: 500px;
}

.h-full {
  height: 100%;
}

.h-half {
  height: 50%;
}

.h-quarter {
  height: 25%;
}

.h-fit {
  height: fit-content;
}

.h-0 {
  height: 0px !important;
}

.h-10 {
  height: 10px;
}

.h-12 {
  height: 12px;
}

.h-20 {
  height: 20px;
}

.h-25 {
  height: 25px;
}

.h-30 {
  height: 30px;
}

.h-40 {
  height: 40px;
}

.h-50 {
  height: 50px;
}

.h-60 {
  height: 60px;
}

.h-70 {
  height: 70px;
}

.h-75 {
  height: 75px;
}

.h-80 {
  height: 80px;
}

.h-100 {
  height: 100px
}

.h-150 {
  height: 150px;
}

.h-160 {
  height: 160px;
}

.h-200 {
  height: 200px
}

.h-250 {
  height: 250px;
}

.h-300 {
  height: 300px;
}

.h-350 {
  height: 350px;
}

.h-400 {
  height: 400px;
}

.h-450 {
  height: 450px;
}

.h-500 {
  height: 500px;
}

.h-600 {
  height: 600px;
}

.h-2rem {
  height: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}


.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.m-0 {
  margin: 0px !important;
}

.m-5 {
  margin: 5px;
}

.m-60 {
  margin: 60px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.text-justify {
  text-align: justify;
}

.text-center {
  text-align: center;
}


.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.border-radius-5 {
  border-radius: 5px;
  --border-radius: 5px;
}

.border-radius-10 {
  border-radius: 10px;
  --border-radius: 10px;
}

.border-radius-20 {
  border-radius: 20px;
  --border-radius: 20px;
}

.border-radius-100 {
  border-radius: 100%;
  --border-radius: 100%;
}

.border-radius-half {
  border-radius: 50%;
  --border-radius: 50%;
}


.border-bottom {
  border-bottom: 1px;
}

.border-top {
  border-top: 1px;
}

.border-left {
  border-left: 1px;
}

.border-right {
  border-right: 1px;
}

.border-solid {
  border-style: solid;
}

.border-grey {
  border: 1px solid rgba(161, 170, 174, 0.1);
}

.grey {
  color: rgba(134, 140, 142, 1);
}

.white {
  color: white;
}

.black {
  color: black;
}

.danger {
  color: var(--ion-color-danger);
  --color: var(--ion-color-danger);
}

.success {
  color: var(--ion-color-success);
  --color: var(--ion-color-success);
}

.primary {
  color: var(--ion-color-primary);
  --color: var(--ion-color-primary);
}

.secondary {
  color: var(--ion-color-secondary);
  --color: var(--ion-color-secondary);

}

.gold {
  color: rgb(211, 189, 119);
  --color: rgb(211, 189, 119);
}

.red {
  color: red;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-auto {
  overflow: auto;
}