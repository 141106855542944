// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

	/** tn-primary **/
	// --ion-color-primary: #e3cb80;
	// --ion-color-primary-rgb: 227, 203, 128;
	// --ion-color-primary-contrast: #000000;
	// --ion-color-primary-contrast-rgb: 0, 0, 0;
	// --ion-color-primary-shade: #c8b371;
	// --ion-color-primary-tint: #e6d08d;

	/** primary **/
	--ion-color-primary: #71b5e0;
	--ion-color-primary-rgb: 61, 194, 255;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #36abe0;
	--ion-color-primary-tint: #50c8ff;


	/** secondary **/
	--ion-color-secondary: #A2D0ED;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	--ion-color-tertiary: #df759e;
	--ion-color-tertiary-rgb: 239, 192, 210;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #d2a9b9;
	--ion-color-tertiary-tint: #f1c6d7;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #FF5757;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #e6e9ea;
	--ion-color-dark-tint: #ededed;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #FFFFFF;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	/** tn-light **/
	--ion-color-tn-light: #FFFFFF;
	--ion-color-tn-light-rgb: 249, 245, 240;
	--ion-color-tn-light-contrast: #353235;
	--ion-color-tn-light-contrast-rgb: 0, 0, 0;
	--ion-color-tn-light-shade: #353235;
	--ion-color-tn-light-tint: #353235;

	/** tn-black **/
	--ion-color-tn-black: #353235;
	--ion-color-tn-black-rgb: 53, 50, 53;
	--ion-color-tn-black-contrast: #ffffff;
	--ion-color-tn-black-contrast-rgb: 255, 255, 255;
	--ion-color-tn-black-shade: #2f2c2f;
	--ion-color-tn-black-tint: #494749;

	/** tn-grey **/
	--ion-color-tn-grey: #F9F5F0;
	--ion-color-tn-grey-rgb: 249, 245, 240;
	--ion-color-tn-grey-contrast: #000000;
	--ion-color-tn-grey-contrast-rgb: 0, 0, 0;
	--ion-color-tn-grey-shade: #dbd8d3;
	--ion-color-tn-grey-tint: #faf6f2;

	/** tn-beige **/
	--ion-color-tn-beige: #f7efe6;
	--ion-color-tn-beige-rgb: 247, 239, 230;
	--ion-color-tn-beige-contrast: #000000;
	--ion-color-tn-beige-contrast-rgb: 0, 0, 0;
	--ion-color-tn-beige-shade: #d9d2ca;
	--ion-color-tn-beige-tint: #f8f1e9;

	/** tn-rose **/
	--ion-color-tn-rose: #df759e;
	--ion-color-tn-rose-rgb: 239, 192, 210;
	--ion-color-tn-rose-contrast: #FFFFFF;
	--ion-color-tn-rose-contrast-rgb: 0, 0, 0;
	--ion-color-tn-rose-shade: #d2a9b9;
	--ion-color-tn-rose-tint: #f1c6d7;

	/** tn-blue **/
	--ion-color-tn-blue: #71b5e0;
	--ion-color-tn-blue-rgb: 162, 208, 237;
	--ion-color-tn-blue-contrast: #FFFFFF;
	--ion-color-tn-blue-contrast-rgb: 0, 0, 0;
	--ion-color-tn-blue-shade: #8fb7d1;
	--ion-color-tn-blue-tint: #abd5ef;

	/** tn-orange **/
	--ion-color-tn-orange: #ffb86c;
	--ion-color-tn-orange-rgb: 255, 198, 137;
	--ion-color-tn-orange-contrast: #FFFFFF;
	--ion-color-tn-orange-contrast-rgb: 0, 0, 0;
	--ion-color-tn-orange-shade: #e0ae79;
	--ion-color-tn-orange-tint: #ffcc95;
}

.ion-color-tn-primary {
	--ion-color-base: var(--ion-color-tn-primary);
	--ion-color-base-rgb: var(--ion-color-tn-primary-rgb);
	--ion-color-contrast: var(--ion-color-tn-primary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tn-primary-contrast-rgb);
	--ion-color-shade: var(--ion-color-tn-primary-shade);
	--ion-color-tint: var(--ion-color-tn-primary-tint);
}

.ion-color-tn-light {
	--ion-color-base: var(--ion-color-tn-light);
	--ion-color-base-rgb: var(--ion-color-tn-light-rgb);
	--ion-color-contrast: var(--ion-color-tn-light-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tn-light-contrast-rgb);
	--ion-color-shade: var(--ion-color-tn-light-shade);
	--ion-color-tint: var(--ion-color-tn-light-tint);
}

.ion-color-tn-black {
	--ion-color-base: var(--ion-color-tn-black);
	--ion-color-base-rgb: var(--ion-color-tn-black-rgb);
	--ion-color-contrast: var(--ion-color-tn-black-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tn-black-contrast-rgb);
	--ion-color-shade: var(--ion-color-tn-black-shade);
	--ion-color-tint: var(--ion-color-tn-black-tint);
}

.ion-color-tn-grey {
	--ion-color-base: var(--ion-color-tn-grey);
	--ion-color-base-rgb: var(--ion-color-tn-grey-rgb);
	--ion-color-contrast: var(--ion-color-tn-grey-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tn-grey-contrast-rgb);
	--ion-color-shade: var(--ion-color-tn-grey-shade);
	--ion-color-tint: var(--ion-color-tn-grey-tint);
}

.ion-color-tn-beige {
	--ion-color-base: var(--ion-color-tn-beige);
	--ion-color-base-rgb: var(--ion-color-tn-beige-rgb);
	--ion-color-contrast: var(--ion-color-tn-beige-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tn-beige-contrast-rgb);
	--ion-color-shade: var(--ion-color-tn-beige-shade);
	--ion-color-tint: var(--ion-color-tn-beige-tint);
}

.ion-color-tn-rose {
	--ion-color-base: var(--ion-color-tn-rose);
	--ion-color-base-rgb: var(--ion-color-tn-rose-rgb);
	--ion-color-contrast: var(--ion-color-tn-rose-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tn-rose-contrast-rgb);
	--ion-color-shade: var(--ion-color-tn-rose-shade);
	--ion-color-tint: var(--ion-color-tn-rose-tint);
}

.ion-color-tn-blue {
	--ion-color-base: var(--ion-color-tn-blue);
	--ion-color-base-rgb: var(--ion-color-tn-blue-rgb);
	--ion-color-contrast: var(--ion-color-tn-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tn-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-tn-blue-shade);
	--ion-color-tint: var(--ion-color-tn-blue-tint);
}

.ion-color-tn-orange {
	--ion-color-base: var(--ion-color-tn-orange);
	--ion-color-base-rgb: var(--ion-color-tn-orange-rgb);
	--ion-color-contrast: var(--ion-color-tn-orange-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tn-orange-contrast-rgb);
	--ion-color-shade: var(--ion-color-tn-orange-shade);
	--ion-color-tint: var(--ion-color-tn-orange-tint);
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #e3cb80;
//     --ion-color-primary-rgb: 227, 203, 128;
//     --ion-color-primary-contrast: #000000;
//     --ion-color-primary-contrast-rgb: 0, 0, 0;
//     --ion-color-primary-shade: #c8b371;
//     --ion-color-primary-tint: #e6d08d;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80,200,255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255,255,255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106,100,255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255,255,255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47,223,117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255,213,52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255,73,97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244,245,248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0,0,0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152,154,162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34,36,40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255,255,255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0,0,0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }


//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18,18,18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }